import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { EventManagerService } from 'app/shared/utils/event-manager.service';
import {
    arraysEqual,
    eachFirstLetterToUpperCase
} from 'app/shared/utils/shared-methods.utils';
import { Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Entity } from '../../shared/interfaces/EntityInterface';
import { ListElement } from '../../shared/utils/ListManager';
import { ServerService } from './server.service';
import { environment } from 'environments/environment';
@Injectable({ providedIn: 'root' })
export class EntityService {
    private entities: Entity[];
    private entityMap: Map<string, Entity>;
    private localisations: ListElement[];
    private brands: ListElement[];
    private country: ListElement[];
    private opsarea: ListElement[];
    private ownerships: ListElement[];
    //private comparables: ListElement[];
    private perimeterGrp: ListElement[];
    private perimeter5yp: ListElement[];
    public defaultHotelList: { htl_ids: string; label: string[] };
    private selectedEntity: Entity;
    public selectedEntityLabel: string[] = [];
    public selectedEntityIds: string[];
    public selectedHtlId: number[];
    public on_ready: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public on_default_hotel_list: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    //14/03/2019 - Aubry - Changed BehaviorSubject to Subject to avoid subscribers to be triggered directly when created.
    public on_change = new Subject();
    public on_selectAll: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public on_manual_set: BehaviorSubject<Record<string, unknown>> = new BehaviorSubject(
        {}
    );
    private _selectedListAvailables = false;
    // Maps
    public mapOpTheatreEntities: Map<number, number[]> = new Map<number, number[]>();
    public mapOpsAreaEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapPrevOpsAreaEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapCountriesEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapBrandEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapOwnershipEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapPerimeterGrpEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapPerimeter5ypEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapComparableEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapComparableNyEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapComparableLyEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapRevAreaEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapRmcFlagEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapCitiesEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapSourceEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapRoomsEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapDataCheckEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapMEClustersEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapCCFlagEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapHotelTypeEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapMarketTypeEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapMEClusterEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapFocusFlagEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapMdAreaEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapStatusEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapArmZoneEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapDrmZoneEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapDopEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapHotelPlaceEntities: Map<any, any[]> = new Map<any, any[]>();
    public mapRRFsmEntities: Map<any, any[]> = new Map<any, any[]>();
    // Selecteds
    public selectedEntities: Entity[];
    public selectedTheatre: string[];
    public selectedRegion: string[];
    public selectedRegionNotFully: string[];
    public selectedCountry: string[];
    public selectedBrand: string[];
    public selectedContract: string[];
    public selectedPerimeterGrp: string[];
    public selectedPerimeter5yp: string[];
    public selectedPerimeter: string[];
    public selectedPerimeterNy: string[];
    public selectedPerimeterLy: string[];
    public selectedRevArea: string[];
    public selectedRmcFlag: string[];
    public selectedCities: string[];
    public selectedSource: string[]; // Not an entity filter
    public selectedRooms: string[];
    public selectedCCFlag: string[];
    public selectedFocusFlag: string[];
    public selectedHotelType: string[];
    public selectedMarketType: string[];
    public selectedMECluster: string[];
    public selectedPrevOpsArea: string[];
    public selectedMdArea: string[];
    public selectedStatus: string[];
    public selectedArmZone: string[];
    public selectedDrmZone: string[];
    public selectedDop: string[];
    public selectedHotelPlace: string[];
    public selectedRRFsm: string[];
    /**
     * It's not after the entity-sidebar.component.ts '_updateSeletedItemKeysAfterInit' when the
     * selecteds arrays stops being undefined (they will be updated by other ways but this method will set their first values).
     * So, for every other component who wants to read this arrays, it's better to wait to this promise.
     */
    public initSelectedsPromise: Promise<any>;
    public options_title: string; //to fix issue on dashboard.component.html
    //src\app\core\components\dashboard\dashboard.component.html(176,90): : Property 'options_title' does not exist on type 'EntityService'.
    private revAreas: any[];
    private revAreasLabels: any[];
    private cities: any[];
    private citiesLabels: any[];
    private hotel_type: any[];
    private hotel_type_Labels: any[];
    private market_type: any[];
    private market_type_Labels: any[];
    private me_cluster: any[];
    private me_cluster_labels: any[];
    private rooms: any[];
    private roomsLabels: any[];
    private theatres: any[];
    private theatresLabels: any[];
    private prevOpsArea: any[];
    private prevOpsAreaLabels: any[];
    private mdArea: any[];
    private mdAreaLabels: any[];
    constructor(
        private serverService: ServerService,
        private eventManagerService: EventManagerService
    ) {}
    /**
     * Call to entity/lists and start/restart the variables.
     * After the response on_ready Behavior will be triggered.
     *
     * (For now, only called on UserService.setData)
     */
    public init(): void {
        this.serverService.get('entity/lists', {}, (data) => {
            this.localisations = data.localisations;
            this.brands = data.brands;
            this.ownerships = data.ownerships;
            //this.comparables = data.comparables;
            this.perimeterGrp = data.perimeterGrp;
            this.perimeter5yp = data.perimeter5yp;
            // Init maps
            this.mapOpTheatreEntities = new Map<number, number[]>();
            this.mapOpsAreaEntities = new Map<any, any[]>();
            this.mapPrevOpsAreaEntities = new Map<any, string[]>();
            this.mapCountriesEntities = new Map<any, string[]>();
            this.mapBrandEntities = new Map<any, string[]>();
            this.mapOwnershipEntities = new Map<any, string[]>();
            this.mapPerimeter5ypEntities = new Map<any, string[]>();
            this.mapPerimeterGrpEntities = new Map<any, string[]>();
            this.mapRevAreaEntities = new Map<any, string[]>();
            this.mapRmcFlagEntities = new Map<any, string[]>();
            this.mapCitiesEntities = new Map<any, string[]>();
            this.mapHotelTypeEntities = new Map<any, string[]>();
            this.mapMarketTypeEntities = new Map<any, string[]>();
            this.mapMEClusterEntities = new Map<any, string[]>();
            this.mapRoomsEntities = new Map<any, string[]>();
            this.mapDataCheckEntities = new Map<any, string[]>();
            this.mapMEClustersEntities = new Map<any, string[]>();
            this.mapCCFlagEntities = new Map<any, string[]>();
            this.mapFocusFlagEntities = new Map<any, string[]>();
            this.mapMdAreaEntities = new Map<any, string[]>();
            this.mapStatusEntities = new Map<any, string[]>();
            // Fill maps
            const _localisations = this.getLocalisations(true);
            if (environment.company === 'rhg') {
                for (const x of _localisations) {
                    if (!this.mapOpsAreaEntities.get(+x.parent)) {
                        this.mapOpsAreaEntities.set(+x.parent, []);
                    }
                    this.mapOpsAreaEntities.get(+x.parent).push(+x.id);
                }
            }
            for (const ent of this.entities) {
                if (environment.company === 'lhg') {
                    this._updateMap(this.mapCountriesEntities, ent, 'country');
                    this._updateMap(this.mapOpsAreaEntities, ent, 'ops_area');
                    this._updateMap(this.mapArmZoneEntities, ent, 'arm_zone');
                    this._updateMap(this.mapDrmZoneEntities, ent, 'drm_zone');
                    this._updateMap(this.mapDopEntities, ent, 'dop');
                    this._updateMap(this.mapHotelPlaceEntities, ent, 'hotel_place');
                    this._updateMap(this.mapRRFsmEntities, ent, 'rr_fsm');
                } else {
                    this._updateMap(this.mapCountriesEntities, ent, 'localisation_id');
                }
                this._updateMap(this.mapOpTheatreEntities, ent, 'theatre');
                this._updateMap(this.mapBrandEntities, ent, 'brand');
                this._updateMap(this.mapOwnershipEntities, ent, 'ownership_id');
                this._updateMap(this.mapRevAreaEntities, ent, 'rev_area');
                this._updateMap(this.mapRmcFlagEntities, ent, 'rmc_flag');
                this._updateMap(this.mapCitiesEntities, ent, 'city');
                this._updateMap(this.mapRoomsEntities, ent, 'room_count');
                this._updateMap(this.mapDataCheckEntities, ent, 'data_check');
                this._updateMap(this.mapMEClustersEntities, ent, 'me_cluster');
                this._updateMap(this.mapPerimeter5ypEntities, ent, 'perimeter_5yp');
                this._updateMap(this.mapPerimeterGrpEntities, ent, 'perimeter_grp');
                this._updateMap(this.mapCCFlagEntities, ent, 'cc');
                this._updateMap(this.mapFocusFlagEntities, ent, 'focus');
                this._updateMap(this.mapHotelTypeEntities, ent, 'hotel_type');
                this._updateMap(this.mapMarketTypeEntities, ent, 'market_type');
                this._updateMap(this.mapMEClusterEntities, ent, 'me_cluster');
                this._updateMap(this.mapPrevOpsAreaEntities, ent, 'prev_ops_area');
                this._updateMap(this.mapMdAreaEntities, ent, 'md_area');
                this._updateMap(this.mapStatusEntities, ent, 'STATUS');
            }
            this.on_ready.next(true);
        });
    }
    /**
     * Start/restart the entities.
     * Select all entities by default or the founded at the localStorage if 'selected-entity' exists
     *
     * (For now, only called on UserService.setData)
     */
    public setEntities(entities: Entity[], defaults: string): void {
        this.entityMap = new Map<string, Entity>();
        for (const entity of entities) {
            entity.city = eachFirstLetterToUpperCase(entity.city);
            if (entity.hotel_type == '') {
                // They are some finance entities that we need to keep for selection in Pulse to match Operational report data
                entity.hotel_type = 'Other Resort';
                this.entityMap.set(entity.id, entity);
            } else {
                this.entityMap.set(entity.id, entity);
            }
        }
        this.entities = [...new Set(this.entityMap.values())];
        if (this.defaultHotelList) {
            localStorage.setItem('selected-entity', this.defaultHotelList.htl_ids);
            localStorage.setItem(
                'selected-entity-label',
                this.defaultHotelList.label.toString()
            );
        }
        const selectedEntities = localStorage.getItem('selected-entity');
        if (selectedEntities) {
            this.setSelectedEntities(selectedEntities.split(','));
            this.selectedEntityLabel = localStorage.getItem('selected-entity-label')
                ? localStorage.getItem('selected-entity-label').split(',')
                : this.selectedEntityLabel;
        } else {
            if (defaults != '') {
                this.setSelectedEntities(defaults.split(','));
            } else this.selectAllEntities(); // select all the hotels
        }
    }
    /**
     * Update the selectedEntityLabel array, and set it on localStorage 'selected-entity-label'
     * If the selectedEntityIds array isn't equals to the ids param, override it too and update the localStorage 'selected-entity'.
     * Trigger on_change Behavior at the end (if not avoided)
     * @param ids
     * @param avoidOnChange
     */
    public setSelectedEntities(ids: string[], avoidOnChange?: boolean): void {
        if (!arraysEqual(ids, this.selectedEntityIds)) {
            this.selectedEntityIds = ids;
            this.setSelectedHtlIds(ids);
            this.selectedEntityLabel = [];
            // comment avoid error ExpressionChangedAfterItHasBeenCheckedError but disable updates on selectionUpdate on reports
            // this.on_manual_set.next({type:'custom', name:ids});
            localStorage.setItem('selected-entity', this.selectedEntityIds.toString());
        }
        if (this._selectedListAvailables) {
            this.updateSelectedEntityLabel();
        }
        if (!avoidOnChange) {
            this.on_change.next(true);
        }
        if (ids.length === 1) {
            this.selectedEntity = this.getEntity(ids[0]);
        }
        this.eventManagerService.broadcast({
            name: 'selectedEntityUpdate',
            data: this.selectedHtlId
        });

        this.eventManagerService.broadcast({
            name: 'updateFilters',
            data: this.selectedHtlId
        });
        Sentry.configureScope((scope) => {
            scope.setExtra('selectedEntity', ids);
        });
    }
    public updateSelectedEntityLabel(): void {
        this._selectedListAvailables = true;
        this.selectedEntityLabel = [];
        if (this.selectedEntityIds === null || this.selectedEntityIds === undefined)
            // guard
            return;
        if (this.selectedEntityIds!.length === 1) {
            // 1 hotel selected
            this.selectedEntityLabel.push(this.getEntity(this.selectedEntityIds[0]).name);
        } else if (
            this.selectedEntityIds!.length > 1 &&
            this.selectedEntityIds!.length <= 3
        ) {
            // between 2 and 3 hotels selected
            this.selectedEntityLabel = this.selectedEntityIds;
        } else if (this.selectedEntityIds!.length > 3) {
            // more than 3 hotels selected
            if (this.selectedEntityIds!.length === this.entities.length) {
                // all hotels selected
                this.selectedEntityLabel.push('All Hotels');
            } else if (
                this.selectedRegion.length > 0 &&
                this.selectedRegionNotFully.length === 0
            ) {
                // multiple regions selected but not partially
                // if rhg then use region, if lhg then use country
                if (environment.company === 'rhg') {
                    this.selectedEntityLabel.push(
                        `${this.selectedRegion.join()}` +
                            ' (' +
                            this.selectedContract
                                .filter((arr) => arr)
                                .map((item) => item.substr(0, 1))
                                .toString()
                                .replace(/,/g, '+') +
                            ')'
                    );
                } else {
                    this.selectedEntityLabel.push(
                        `${this.selectedCountry.join()}` +
                            ' (' +
                            this.selectedContract
                                .filter((arr) => arr)
                                .map((item) => item.substr(0, 1))
                                .toString()
                                .replace(/,/g, '+') +
                            ')'
                    );
                }
            } else if (
                this.selectedRegion.length > 0 &&
                this.selectedRegionNotFully.length > 0
            ) {
                // multiple regions selected
                this.selectedEntityLabel.push(
                    `${this.selectedRegion.join()},${this.selectedRegionNotFully.toString()}` +
                        ' (' +
                        this.selectedContract
                            .filter((arr) => arr)
                            .map((item) => item.substr(0, 1))
                            .toString()
                            .replace(/,/g, '+') +
                        ')'
                );
            } else {
                if (
                    this.selectedCountry.length === 0 &&
                    this.selectedRegionNotFully.length === 0
                ) {
                    const selectedEntityLabelString = localStorage.getItem('selected-entity-label');
                    this.selectedEntityLabel = selectedEntityLabelString ? selectedEntityLabelString.split(',') : [];
                    // multiple countries selected but not partially
                    // this.selectedEntityLabel = localStorage
                    //     .getItem('selected-entity-label')
                    //     .split(',');
                } else if (this.selectedCountry.length < 6) {
                    // less than 6 countries selected
                    this.selectedEntityLabel.push(
                        this.selectedCountry.filter((arr) => arr).toString() +
                            ' (' +
                            this.selectedContract
                                .filter((arr) => arr)
                                .map((item) => item.substr(0, 1))
                                .toString()
                                .replace(/,/g, '+') +
                            ')'
                    );
                } else {
                    this.selectedEntityLabel.push(
                        this.selectedRegionNotFully.toString() +
                            ' (' +
                            this.selectedContract
                                .filter((arr) => arr)
                                .map((item) => item.substr(0, 1))
                                .toString()
                                .replace(/,/g, '+') +
                            ')'
                    );
                }
            }
        } else {
            if (this.selectedCountry.length < 6) {
                this.selectedEntityLabel.push(
                    this.selectedCountry.filter((arr) => arr).toString() +
                        ' (' +
                        this.selectedContract
                            .filter((arr) => arr)
                            .map((item) => item.substr(0, 1))
                            .toString()
                            .replace(/,/g, '+') +
                        ')'
                );
            } else {
                this.selectedEntityLabel.push(
                    this.selectedRegionNotFully.toString() +
                        ' (' +
                        this.selectedContract
                            .filter((arr) => arr)
                            .map((item) => item.substr(0, 1))
                            .toString()
                            .replace(/,/g, '+') +
                        ')'
                );
            }
        }
        localStorage.setItem(
            'selected-entity-label',
            this.selectedEntityLabel.toString()
        );
    }
    /**
     * Update the selectedEntityIds array with all the ids of the 'entities' array,
     * trigger on_selectAll Behavior at the end.
     */
    public selectAllEntities(): void {
        const ids: string[] = [];
        for (const entity of this.entities) {
            ids.push(entity.id);
        }
        this.selectedEntityIds = ids;
        this.setSelectedEntities(ids);
        this.setSelectedHtlIds(ids);
        this.on_selectAll.next(true);
    }
    getEntities(): Entity[] {
        return this.entities;
    }
    getEntity(id: string): Entity {
        return this.entityMap.get(id);
    }
    getEntityIdByName(name: string): string {
        const _entity = this.entities.find((node) => node.name === name);
        return String(_entity.id);
    }
    getEntityLongNameByName(name: string): string {
        const _entity = this.entities.find((node) => node.name === name);
        return String(_entity?.long_name);
    }
    getHtlIdByCode(htlcd: string): number[] {
        const _entity = this.entities.find((node) => node.id === htlcd);
        return _entity ? _entity.htl_id.split(',').map((id) => +id) : null;
    }
    getHtlShortNameByCode(htlcd: string): string {
        const _entity = this.entities.find((node) => node.id === htlcd);
        return String(_entity.name);
    }
    getHtlLongNameByCode(htlcd: string): string {
        const _entity = this.entities.find((node) => node.id === htlcd);
        return String(_entity?.long_name);
    }
    getEntityIdByRevArea(name: string): any[] {
        const _ids: string[] = [];
        for (const entity of this.entities) {
            if (entity.rev_area == name) {
                _ids.push(entity.id);
            }
        }
        return _ids;
    }
    getEntityIdByOpsArea(name: string): any[] {
        const _ids: string[] = [];
        for (const entity of this.entities) {
            if (entity.ops_area == name) {
                _ids.push(entity.id);
            }
        }
        return _ids;
    }
    getEntityIdByPrevOpsArea(name: string): any[] {
        const _ids: string[] = [];
        for (const entity of this.entities) {
            if (entity.prev_ops_area == name) {
                _ids.push(entity.id);
            }
        }
        return _ids;
    }
    public getSelectedIds(): string[] {
        return this.selectedEntityIds;
    }
    setEntitiesByLevel(lvl: number, arg: string): void {
        this.selectedEntityLabel = [];
        switch (lvl) {
            case 0:
                this.on_manual_set.next({ type: 'all', name: arg });
                break;
            case 1:
                this.on_manual_set.next({ type: 'location', name: arg });
                break;
            case 2:
                this.on_manual_set.next({ type: 'country', name: arg });
                break;
            case 3:
                this.on_manual_set.next({ type: 'rev_area', name: arg });
                break;
            default:
                this.on_manual_set.next({ type: 'hotel', name: arg });
        }
    }
    getSelectedEntity(): Entity {
        return this.selectedEntity;
    }
    setSelectedEntity(entity: Entity): void {
        this.selectedEntity = entity;
        this.selectedEntityLabel = [entity.name];
        this.selectedEntityIds = [entity.id];
        this.selectedHtlId = entity.htl_id.split(',').map((id) => +id);
        //this.on_change.next(true);
        localStorage.setItem('selected-entity', this.selectedEntityIds.toString());
        localStorage.setItem(
            'selected-entity-label',
            this.selectedEntityLabel.toString()
        );
    }
    public shouldDisplayDefaultHotelList(): Observable<boolean> {
        return this.on_default_hotel_list.asObservable();
    }
    setDefaultHotelList(selection): void {
        this.serverService.post(
            'entity/setDefaultHotelList',
            {
                selection: selection,
                htl_ids: this.selectedEntityIds,
                label: this.selectedEntityLabel
            },
            (data: { htl_ids: string; label: string[] }) => {
                // this.setSelectedEntities([...new Set(data.htl_ids.split(','))]);
                this.setSelectedHtlIds([...new Set(data.htl_ids.split(','))]);
                // this.updateSelectedEntityLabel();
                // this.on_change.next(true);
                this.defaultHotelList = data;
                localStorage.setItem(
                    'selected-entity',
                    this.selectedEntityIds.toString()
                );
                localStorage.setItem(
                    'selected-entity-label',
                    this.selectedEntityLabel.toString()
                );
                this.on_default_hotel_list.next(true);
            }
        );
    }
    getDefaultHotelList(
        selection,
        f: (data: { htl_ids: string; label: string[] }) => void
    ): void {
        if (selection === -1) {
            localStorage.setItem(
                'selected-entity-label',
                this.selectedEntityLabel.toString()
            );
            this.on_default_hotel_list.next(true);
            this.eventManagerService.broadcast({
                name: 'selectedEntityUpdate',
                data: this.selectedHtlId
            });
            return;
        }
        this.serverService.get(
            'entity/getDefaultHotelList',
            { selection: selection },
            (data: { htl_ids: string; label: string[] }) => {
                if (data) {
                    f(data);
                    this.selectedEntityLabel = data.label;
                    this.selectedEntityIds = data.htl_ids.split(',');
                    this.setSelectedHtlIds([...new Set(data.htl_ids.split(','))]);
                    localStorage.setItem(
                        'selected-entity',
                        this.selectedEntityIds.toString()
                    );
                    localStorage.setItem(
                        'selected-entity-label',
                        this.selectedEntityLabel.toString()
                    );
                    this.on_default_hotel_list.next(true);
                    this.eventManagerService.broadcast({
                        name: 'selectedEntityUpdate',
                        data: this.selectedHtlId
                    });
                    this.on_change.next(true);
                }
            }
        );
    }
    selectByLocation(loc: string): void {
        const _ids: string[] = [];
        for (const entity of this.entities) {
            if (entity.parent_name == loc) {
                _ids.push(entity.id);
            }
        }
        this.selectedRegion = [];
        this.selectedRegion.push(loc);
        this.setSelectedEntities(_ids);
        /*
            this.selectedEntityIds = _ids;
            this.selectedEntityLabel = [loc];
            this.on_change.next(true);
        */
    }
    selectByRevArea(loc: string): void {
        const _ids: string[] = [];
        for (const entity of this.entities) {
            if (entity.rev_area == loc) {
                _ids.push(entity.id);
            }
        }
        this.selectedRegion = [];
        this.selectedRegion.push(loc);
        this.setSelectedEntities(_ids);
        /*
        this.selectedEntityIds = _ids;
        this.selectedEntityLabel = [loc];
        this.on_change.next(true);
        */
    }
    selectByCountry(loc: string): void {
        const _ids: string[] = [];
        for (const entity of this.entities) {
            if (entity.country == loc) {
                _ids.push(entity.id);
            }
        }
        this.setSelectedEntities(_ids);
    }
    selectByHotel(loc: string): void {
        const _ids: string[] = [];
        for (const entity of this.entities) {
            if (entity.name == loc) {
                _ids.push(entity.id);
            }
        }
        this.setSelectedEntities(_ids);
    }
    getLocalisations(filterUpponAccess = false): ListElement[] {
        if (this.localisations) {
            if (filterUpponAccess) {
                if (environment.company === 'lhg') {
                    let ids = this.entities.map((entity) => entity.ops_area);
                    ids = [...new Set(ids)];
                    const res = [];
                    for (const b of this.localisations) {
                        if (ids.indexOf(b.label) != -1) {
                            res.push(b);
                        }
                    }
                    return res;
                } else {
                    let ids = this.entities.map((entity) => entity.localisation_id);
                    ids = [...new Set(ids)];
                    const res = [];
                    for (const b of this.localisations) {
                        if (ids.indexOf(Number(b.id)) != -1) {
                            res.push(b);
                        }
                    }
                    return res;
                }
            }
            return this.localisations;
        }
    }
    getCountry(): Array<ListElement> {
        let countryIds = this.entities.map((entity) => entity.country);
        countryIds = [...new Set(countryIds)];
        const res: Array<ListElement> = [];
        for (const b of countryIds) {
            res.push({ id: b, label: b });
        }
        return res;
    }
    getOpsArea(): Array<ListElement> {
        let opsareaIds = this.entities.map((entity) => entity.ops_area);
        opsareaIds = [...new Set(opsareaIds)];
        const res: Array<ListElement> = [];
        for (const b of opsareaIds) {
            res.push({ id: b, label: b });
        }
        return res;
    }
    getBrands(filterUpponAccess = false): Array<ListElement> {
        if (filterUpponAccess) {
            let brandIds = this.entities.map((entity) => entity.brand);
            brandIds = [...new Set(brandIds)];
            const res: Array<ListElement> = [];
            for (const b of this.brands) {
                if (brandIds.indexOf(b.label) != -1) {
                    res.push(b);
                }
            }
            return res;
        }
        return this.brands;
    }
    getOwnerships(filterUpponAccess = false): Array<ListElement> {
        if (filterUpponAccess) {
            let ids = this.entities.map((entity) => entity.ownership_id);
            ids = [...new Set(ids)];
            const res: Array<ListElement> = [];
            for (const b of this.ownerships) {
                if (ids.indexOf(Number(b.id)) != -1) {
                    res.push(b);
                }
            }
            return res;
        }
        return this.ownerships;
    }
    /*  getComparables(filterUpponAccess: boolean = false) {
        if (filterUpponAccess) {
            let ids = this.entities.map(entity => entity.comparable_id);
            ids = [...new Set(ids)];
            var res = [];
            for (let b of this.comparables) {
                if (ids.indexOf(Number(b.id)) != -1) {
                    res.push(b);
                }
            }
            return res;
        }
        return this.comparables;
    }
    getComparablesLY(filterUpponAccess: boolean = false) {
        if (filterUpponAccess) {
            let ids = this.entities.map(entity => entity.comparable_id_ly);
            ids = [...new Set(ids)];
            var res = [];
            for (let b of this.comparables) {
                if (ids.indexOf(Number(b.id)) != -1) {
                    res.push(b);
                }
            }
            return res;
        }
        return this.comparables;
    }
    getComparablesNY(filterUpponAccess: boolean = false) {
        if (filterUpponAccess) {
            let ids = this.entities.map(entity => entity.comparable_id_ny);
            ids = [...new Set(ids)];
            var res = [];
            for (let b of this.comparables) {
                if (ids.indexOf(Number(b.id)) != -1) {
                    res.push(b);
                }
            }
            return res;
        }
        return this.comparables;
    } */
    /**
     * Return a list of the 5YP Perimeter obtained from the entities
     */
    getPerimeter5YP(filterUpponAccess = false): Array<ListElement> {
        if (filterUpponAccess) {
            /*
            let ids = this.entities.map((entity) => entity.perimeter_5yp_id);
            ids = [...new Set(ids)];
            */
            const ids: number[] = [];
            const res: Array<ListElement> = [];
            for (const e of this.entities) {
                if (ids.indexOf(Number(e.perimeter_5yp_id)) === -1) {
                    ids.push(e.perimeter_5yp_id);
                    res.push({ id: e.perimeter_5yp_id, label: e.perimeter_5yp });
                }
            }
            return res;
        }
        return this.perimeter5yp;
    }
    /**
     * Return a list of the Perimeter Group obtained from the entities
     */
    getPerimeterGrp(filterUpponAccess = false): Array<ListElement> {
        if (filterUpponAccess) {
            /*
            let ids = this.entities.map((entity) => entity.perimeter_grp_id);
            ids = [...new Set(ids)];
            */
            const ids: number[] = [];
            const res: Array<ListElement> = [];
            for (const e of this.entities) {
                if (ids.indexOf(Number(e.perimeter_grp_id)) === -1) {
                    ids.push(e.perimeter_grp_id);
                    res.push({ id: e.perimeter_grp_id, label: e.perimeter_grp });
                }
            }
            return res;
        }
        return this.perimeterGrp;
    }
    /**
     * Return a list of the RevAreas obtained from the entities
     */
    public getRevArea(onlyLabel?: boolean): (string | any)[] {
        if (!this.revAreas) {
            const _revAreas: any[] = [];
            const _revAreasLabels: string[] = [];
            for (const e of this.entities) {
                if (_revAreasLabels.indexOf(e.rev_area) === -1) {
                    // Set 'id' worth??
                    _revAreas.push({ id: e.rev_area, label: e.rev_area });
                    _revAreasLabels.push(e.rev_area);
                }
            }
            this.revAreas = _revAreas;
            this.revAreasLabels = _revAreasLabels;
        }
        return onlyLabel ? this.revAreasLabels : this.revAreas;
    }
    /**
     * Return a list of the Previous Ops Area obtained from the entities
     */
    public getPrevOpsArea(onlyLabel?: boolean): (string | any)[] {
        if (!this.prevOpsArea) {
            const _prevOpsAreas: any[] = [];
            const _prevOpsAreasLabels: string[] = [];
            for (const e of this.entities) {
                if (_prevOpsAreasLabels.indexOf(e.prev_ops_area) === -1) {
                    _prevOpsAreas.push({ id: e.prev_ops_area, label: e.prev_ops_area });
                    _prevOpsAreasLabels.push(e.prev_ops_area);
                }
            }
            this.prevOpsArea = _prevOpsAreas;
            this.prevOpsAreaLabels = _prevOpsAreasLabels;
        }
        return onlyLabel ? this.prevOpsAreaLabels : this.prevOpsArea;
    }
    /**
     * Return a list of the Cities obtained from the entities
     */
    public getCities(onlyLabel?: boolean): (string | any)[] {
        if (!this.cities) {
            const _cities: any[] = [];
            const _citiesLabels: string[] = [];
            for (const e of this.entities) {
                if (_citiesLabels.indexOf(e.city) === -1) {
                    // Set 'id' worth??
                    _cities.push({
                        id: e.city,
                        label: e.city // eachFirstLetterToUpperCase(e.city)
                    });
                    // _citiesLabels.push(eachFirstLetterToUpperCase(e.city));
                    _citiesLabels.push(e.city);
                }
            }
            this.cities = _cities;
            this.citiesLabels = _citiesLabels;
        }
        return onlyLabel ? this.citiesLabels : this.cities;
    }
    /**
     * Return a list of the room count categories obtained from the entities
     */
    public getRooms(onlyLabel?: boolean): (string | any)[] {
        if (!this.rooms) {
            const _rooms: any[] = [];
            const _roomsLabels: string[] = [];
            for (const e of this.entities) {
                if (_roomsLabels.indexOf(e.room_count.toString()) === -1) {
                    _rooms.push({ id: e.room_count, label: e.room_count.toString() });
                    _roomsLabels.push(e.room_count.toString());
                }
            }
            this.rooms = _rooms;
            this.roomsLabels = _roomsLabels;
        }
        return onlyLabel ? this.roomsLabels : this.rooms;
    }
    /**
     * Return a list of the room count categories obtained from the entities
     */
    public getTheatres(onlyLabel?: boolean): (string | any)[] {
        if (!this.theatres) {
            const _theatres: any[] = [];
            const _theatresLabels: string[] = [];
            for (const e of this.entities) {
                if (_theatresLabels.indexOf(e.theatre.toString()) === -1) {
                    _theatres.push({ id: e.theatre, label: e.theatre.toString() });
                    _theatresLabels.push(e.theatre.toString());
                }
            }
            this.theatres = _theatres;
            this.theatresLabels = _theatresLabels;
        }
        return onlyLabel ? this.theatresLabels : this.theatres;
    }
    /**
     * Return a list of the Hotel Types obtained from the entities
     */
    public getHotelType(onlyLabel?: boolean): (string | any)[] {
        if (!this.hotel_type) {
            const _hotel_type: any[] = [];
            const _hotel_type_Labels: string[] = [];
            for (const e of this.entities) {
                if (_hotel_type_Labels.indexOf(e.hotel_type) === -1) {
                    // Set 'id' worth??
                    _hotel_type.push({
                        id: e.hotel_type,
                        label: e.hotel_type
                    });
                    _hotel_type_Labels.push(e.hotel_type);
                }
            }
            this.hotel_type = _hotel_type;
            this.hotel_type_Labels = _hotel_type_Labels;
        }
        return onlyLabel ? this.hotel_type_Labels : this.hotel_type;
    }
    /**
     * Return a list of the Market Types obtained from the entities
     */
    public getMarketType(onlyLabel?: boolean): (string | any)[] {
        if (!this.market_type) {
            const _market_type: any[] = [];
            const _market_type_Labels: string[] = [];
            for (const e of this.entities) {
                if (_market_type_Labels.indexOf(e.market_type) === -1) {
                    // Set 'id' worth??
                    _market_type.push({
                        id: e.market_type,
                        label: e.market_type !== '' ? e.market_type : 'Other'
                    });
                    _market_type_Labels.push(e.market_type);
                }
            }
            this.market_type = _market_type;
            this.market_type_Labels = _market_type_Labels;
        }
        return onlyLabel ? this.market_type_Labels : this.market_type;
    }
    /**
     * Return a list of the M&E cluster (clubs) obtained from the entities
     */
    public getMECluster(onlyLabel?: boolean): (string | any)[] {
        if (!this.me_cluster) {
            const _me_cluster: any[] = [];
            const _me_cluster_labels: string[] = [];
            for (const e of this.entities) {
                if (_me_cluster_labels.indexOf(e.me_cluster) === -1) {
                    // Set 'id' worth??
                    _me_cluster.push({
                        id: e.me_cluster,
                        label:
                            e.me_cluster && e.me_cluster !== '' ? e.me_cluster : 'No Club'
                    });
                    _me_cluster_labels.push(e.me_cluster);
                }
            }
            this.me_cluster = _me_cluster;
            this.me_cluster_labels = _me_cluster_labels;
        }
        return onlyLabel ? this.me_cluster_labels : this.me_cluster;
    }
    /**
     * Return a list of the MD Area obtained from the entities
     */
    public getMdArea(): Array<ListElement> {
        let mdareaIds = this.entities.map((entity) => entity.md_area);
        mdareaIds = [...new Set(mdareaIds)];
        const res: Array<ListElement> = [];
        for (const b of mdareaIds) {
            res.push({ id: b, label: b !== '' ? b : 'Other' });
        }
        return res;
    }
    /**
     * Return a list of the Perimeter Group obtained from the entities
     */
    getStatus(): Array<ListElement> {
        let statusId = this.entities.map((entity) => entity.STATUS);
        statusId = [...new Set(statusId)];
        const res: Array<ListElement> = [];
        for (const b of statusId) {
            res.push({ id: b, label: b !== '' ? b : 'Other' });
        }
        return res;
    }
    /**
     * Return a list of the ARM zone obtained from the entities
     */
    public getArmZone(): Array<ListElement> {
        let ids = this.entities.map((entity) => entity.arm_zone);
        ids = [...new Set(ids)];
        const res: Array<ListElement> = [];
        for (const b of ids) {
            res.push({ id: b, label: b !== '' ? b : 'Other' });
        }
        return res;
    }
    /**
     * Return a list of the DRM zone obtained from the entities
     */
    public getDrmZone(): Array<ListElement> {
        let ids = this.entities.map((entity) => entity.drm_zone);
        ids = [...new Set(ids)];
        const res: Array<ListElement> = [];
        for (const b of ids) {
            res.push({ id: b, label: b !== '' ? b : 'Other' });
        }
        return res;
    }
    /**
     * Return a list of the DOP obtained from the entities
     */
    public getDop(): Array<ListElement> {
        let ids = this.entities.map((entity) => entity.dop);
        ids = [...new Set(ids)];
        const res: Array<ListElement> = [];
        for (const b of ids) {
            res.push({ id: b, label: b !== '' ? b : 'Other' });
        }
        return res;
    }
    /**
     * Return a list of the Hotel Place obtained from the entities
     */
    public getHotelPlace(): Array<ListElement> {
        let ids = this.entities.map((entity) => entity.hotel_place);
        ids = [...new Set(ids)];
        const res: Array<ListElement> = [];
        for (const b of ids) {
            res.push({ id: b, label: b !== '' ? b : 'Other' });
        }
        return res;
    }
    /**
     * Return a list of the RR FSM obtained from the entities
     */
    public getRRFsm(): Array<ListElement> {
        let ids = this.entities.map((entity) => entity.rr_fsm);
        ids = [...new Set(ids)];
        const res: Array<ListElement> = [];
        for (const b of ids) {
            res.push({ id: b, label: b !== '' ? b : 'Other' });
        }
        return res;
    }
    /*
        Test required
    */
    getLocationId(name: string): number {
        const obj = this.localisations.find(function (obj) {
            return obj.label === name;
        });
        return Number(obj.id);
    }
    getSelectedEntitiesString(addquotes: boolean): string {
        if (addquotes) {
            return this.getSelectedIds()
                .map((x) => "'" + x + "'")
                .toString();
        } else {
            return this.getSelectedIds()
                .map((x) => x)
                .toString();
        }
    }
    getSelectedHtlIdsString(addquotes: boolean): string {
        if (addquotes) {
            return this.getSelectedHtlIds()
                .map((x) => "'" + x + "'")
                .toString();
        } else {
            return this.getSelectedHtlIds()
                .map((x) => x)
                .toString();
        }
    }

    public getSelectedCurrency(): string {
        if (this.selectedEntityIds.length > 1) return 'EUR';
        else return this.selectedEntity.currency;
    }
    private _updateMap(map: Map<any, any[]>, entity: Entity, param: string): void {
        if (entity[param] !== undefined && entity[param] !== null) {
            if (!map.get(entity[param])) {
                map.set(entity[param], []);
            }
            map.get(entity[param]).push({
                id: entity.id,
                UNIT_CODE: entity.UNIT_CODE,
                SAP_CODE: entity.SAP_CODE,
                htl_id: entity.htl_id
            });
        }
    }
    public setSelectedHtlIds(ids: string[]): void {
        const htl_ids: number[][] = [];
        for (const id of ids) {
            if (this.entityMap.get(id) && this.entityMap.get(id).htl_id) {
                htl_ids.push(
                    this.entityMap
                        .get(id)
                        .htl_id.split(',')
                        .map((id) => +id)
                );
            }
        }
        this.selectedHtlId = [].concat(...htl_ids);
    }
    public getSelectedHtlIds(): number[] {
        return this.selectedHtlId;
    }
    public getSelectedHtlIdsAllowed(allowedHtl: number[]): number[] {
        const _selectedHotels = this.selectedHtlId.filter((htl) =>
            allowedHtl.includes(htl)
        );
        return _selectedHotels;
    }
    public getSelectedHtlIdsAllowedLength(allowedHtl: number[]): number {
        const _selectedHotels = this.selectedHtlId.filter((htl) =>
            allowedHtl.includes(htl)
        );
        const _units = [];
        for (const _h of _selectedHotels) {
            for (const e of this.entities) {
                if (
                    e.htl_id
                        .split(',')
                        .map(Number)
                        .find((id) => id === _h)
                ) {
                    _units.push(e.UNIT_CODE);
                }
            }
        }
        const _unique = [...new Set(_units)];
        return _unique.length;
    }
    /**
     * For filtering out htls for the data check/accuracy in pace and pickup
     * @param arrayToFilter - list of starting htl_ids
     * @param dataCheck - whether or not to filter (0 being no, 1+ being yes)
     * @param revco - true if you're filtering htlcds for revco instead of htl_ids
     */
    public filterExPmsHtlIds(
        arrayToFilter: any[],
        dataCheck: number,
        revco?: boolean
    ): any[] {
        if (revco) {
            let dataCheckCds: string[] = [];
            if (this.mapDataCheckEntities && dataCheck) {
                if (this.mapDataCheckEntities.get('all_ex_pms')) {
                    dataCheckCds = [
                        ...this.mapDataCheckEntities
                            .get('all_ex_pms')
                            .map((node) => node.UNIT_CODE)
                    ];
                }
            }
            return arrayToFilter.filter((htl) => !dataCheckCds.includes(htl));
        } else {
            let dataCheckIds: number[] = [];
            if (this.mapDataCheckEntities && dataCheck) {
                if (this.mapDataCheckEntities.get('all_ex_pms')) {
                    const rawDataCheckIds = [
                        ...this.mapDataCheckEntities
                            .get('all_ex_pms')
                            .map((node) => node.htl_id)
                    ];
                    rawDataCheckIds.forEach((htl_id) =>
                        dataCheckIds.push(htl_id.split(',').map((id) => +id))
                    );
                    dataCheckIds = [].concat(...dataCheckIds);
                }
            }
            if (this.mapDataCheckEntities && dataCheck) {
                if (this.mapDataCheckEntities.get('all_ex_pms_and_me')) {
                    const rawDataCheckIds = [
                        ...this.mapDataCheckEntities
                            .get('all_ex_pms_and_me')
                            .map((node) => node.htl_id)
                    ];
                    rawDataCheckIds.forEach((htl_id) =>
                        dataCheckIds.push(htl_id.split(',').map((id) => +id))
                    );
                    dataCheckIds = [].concat(...dataCheckIds);
                }
            }
            return arrayToFilter.filter((htl) => !dataCheckIds.includes(htl));
        }
    }
    public filterExMeHtlIds(arrayToFilter: any[], dataCheck: number): any[] {
        let dataCheckIds: number[] = [];
        if (this.mapDataCheckEntities && dataCheck) {
            if (this.mapDataCheckEntities.get('all_ex_me')) {
                const rawDataCheckIds = [
                    ...this.mapDataCheckEntities
                        .get('all_ex_me')
                        .map((node) => node.htl_id)
                ];
                rawDataCheckIds.forEach((htl_id) =>
                    dataCheckIds.push(htl_id.split(',').map((id) => +id))
                );
                dataCheckIds = [].concat(...dataCheckIds);
            }
        }
        if (this.mapDataCheckEntities && dataCheck) {
            if (this.mapDataCheckEntities.get('all_ex_pms_and_me')) {
                const rawDataCheckIds = [
                    ...this.mapDataCheckEntities
                        .get('all_ex_pms_and_me')
                        .map((node) => node.htl_id)
                ];
                rawDataCheckIds.forEach((htl_id) =>
                    dataCheckIds.push(htl_id.split(',').map((id) => +id))
                );
                dataCheckIds = [].concat(...dataCheckIds);
            }
        }
        return arrayToFilter.filter((htl) => !dataCheckIds.includes(htl));
    }
}
