/* eslint-disable @typescript-eslint/ban-types */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { GlobalService } from './global.service';
import { CsrfService } from './csrf.service';
import { environment } from 'environments/environment';
import { LocalStorageService } from './local-storage.service';
import { sanitizeStringOnlyAlphaNum } from 'app/shared/utils/shared-methods.utils';
@Injectable({ providedIn: 'root' })
export class ServerService {
    public loadingCpt: number;
    private server: string = environment.apiUrl;
    private headers: HttpHeaders = new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
    });
    constructor(
        public http: HttpClient,
        private router: Router,
        private globalService: GlobalService,
        private csrfService: CsrfService,
        private localStorageService: LocalStorageService
    ) {
        this.headers = this.headers.set('Content-Type', 'application/json');

        this.csrfService.on_ready.subscribe((csrf_ready) => {
            if (csrf_ready) {
                this.headers = this.headers.set(
                    'x-csrf-token',
                    sanitizeStringOnlyAlphaNum(localStorage.getItem('uuid'))
                );
            }
        });

        if (localStorage.getItem('x-access-token') !== null) {
            this.headers = this.headers.set(
                'x-access-token',
                localStorage.getItem('x-access-token')
            );
        }

        // watch for localStorage changes
        this.localStorageService.watchStorage().subscribe((key: string) => {
            if (key === 'uuid') {
                if (!localStorage.getItem('uuid')) {
                }
                this.headers = this.headers.set(
                    'x-csrf-token',
                    localStorage.getItem('uuid')
                );
            } else if (key === '') {
                // empty key means that the storage was cleared -> logout
                this.router.navigate(['/logout']);
            }
        });

        this.loadingCpt = 0;

        if (localStorage.getItem('uuid') !== null) {
            console.log(
                'setCsrfToken from ServerService constructor --> localStorage.getItem(x-csrf-token) !== null)'
            );
            this.csrfService.set(localStorage.getItem('uuid'));
            this.headers = this.headers.set(
                'x-csrf-token',
                this.csrfService.getCsrfToken()
            );
        } else {
            console.log(
                'setCsrfToken from ServerService constructor --> localStorage.getItem(x-csrf-token) === null)'
            );
            this.csrfService.refreshCsrfToken().subscribe(({ csrfToken }) => {
                this.csrfService.set(sanitizeStringOnlyAlphaNum(csrfToken));
                this.headers = this.headers.set('x-csrf-token', sanitizeStringOnlyAlphaNum(csrfToken));
            });
        }
    }
    public setSession(session: string): void {
        localStorage.setItem('x-access-token', sanitizeStringOnlyAlphaNum(session));
        this.headers = this.headers.set('x-access-token', sanitizeStringOnlyAlphaNum(session));
    }

    public get(
        url: string,
        params: { [s: string]: string | number | boolean },
        callBack: Function,
        errorCallBack: Function = undefined,
        unsubscribe?: Subject<void>,
        avoidLoading?: boolean
    ): void {
        this.send_to_server(
            'GET',
            url,
            params,
            callBack,
            errorCallBack,
            unsubscribe,
            avoidLoading
        );
    }
    //The post function is a method that sends an HTTP POST request to the server and takes a callback function that is executed when the response is received.
    //It also has the option to provide an error callback, an unsubscribe subject, and a boolean flag to avoid displaying the loading spinner.
    public post(
        url: string,
        params: any,
        callBack: Function,
        errorCallBack: Function = undefined,
        unsubscribe?: Subject<void>,
        avoidLoading?: boolean
    ): void {
        this.send_to_server(
            'POST',
            url,
            params,
            callBack,
            errorCallBack,
            unsubscribe,
            avoidLoading
        );
    }
    //The post_observe function, on the other hand, returns an observable that sends an HTTP POST request to the server.
    //It sets the headers, converts the data to a JSON string, and handles any errors that may occur.
    public post_observe(url: string, params: any): Observable<any> {
        this.headers = this.headers.set(
            'x-csrf-token',
            localStorage.getItem('uuid')
        );
        const options = {
            headers: this.headers
        };

        this.startLoading();

        //console.log('post_observe', localStorage.getItem('uuid'));
        return this.http.post(this.server + url, JSON.stringify(params), options).pipe(
            map((res) => res),
            catchError((err) => {
                return of(err);
            })
        );
    }
    public datatableAjax(url: string): any {
        return {
            url: this.server + url,
            beforeSend: function (request): void {
                request.setRequestHeader(
                    'x-access-token',
                    localStorage.getItem('x-access-token')
                );
            }
        };
    }
    public startLoading(): void {
        this.loadingCpt++;
        this.globalService.loading(true);
    }
    public stopLoading(): void {
        this.loadingCpt--;
        if (this.loadingCpt <= 0) {
            this.loadingCpt = 0;
            this.globalService.loading(false);
        }
    }
    private send_to_server(
        method: 'POST' | 'GET',
        url: string,
        params: any,
        callBack: Function,
        errorCallBack: Function,
        unsubscribe?: Subject<void>,
        avoidLoading?: boolean
    ): any {
        if (!avoidLoading) {
            this.startLoading();
        }
        let obs: Observable<any>;
        if (method == 'GET') {
            const args: string[] = [];
            for (const key in params) {
                args.push(key + '=' + params[key]);
            }
            const url_args = '?' + args.join('&');
            obs = this.http.get(this.server + url + url_args, {
                headers: this.headers,
                observe: 'response'
            });
        } else {
            obs = this.http.post(this.server + url, params, {
                headers: this.headers,
                observe: 'response'
            });
        }
        if (unsubscribe) {
            obs.pipe(
                takeUntil(unsubscribe),
                map((res) => res)
            ).subscribe(
                (data) => this._onSuccess(data, callBack, errorCallBack, avoidLoading),
                (error) => this._onError(error, errorCallBack, avoidLoading)
            );
        } else {
            obs.pipe(map((res) => res)).subscribe(
                (data) => this._onSuccess(data, callBack, errorCallBack, avoidLoading),
                (error) => this._onError(error, errorCallBack, avoidLoading)
            );
        }
    }

    private _onSuccess(
        data,
        callBack: Function,
        errorCallBack: Function,
        avoidLoading?: boolean
    ): void {
        if (!avoidLoading) {
            this.stopLoading();
        }
        if (data.status >= 200 && data.status < 300) {
            callBack(data.body.data);
        } else {
            if (errorCallBack) {
                errorCallBack(data);
            }
            this.globalService.alert('Server Warning', data.body.status); // Change to error?
        }
    }
    private _onError(data, errorCallBack: Function, avoidLoading?: boolean): void {
        // console.log(error);
        if (!avoidLoading) {
            this.stopLoading();
        }
        if (data.status === 402) {
            console.log('402 error in server.service _onError');
            localStorage.clear();
            this.router.navigateByUrl(
                '/',
                /* Removed unsupported properties by Angular migration: queryParamsHandling. */ {}
            );
        } else if (
            data.status === 401 ||
            data.error.msg === 'Invalid session' ||
            data.error.msg === 'Your session has expired. Please login again.'
        ) {
            console.log('401 error in server.service _onError');
            localStorage.clear();

            this.globalService.alert(
                'Session expired',
                'Dear user, your session has expired. Please log in again.'
            );
            // redirect to login
            this.router.navigate(['/login']);
            return;
        } else if (data.status === 406) {
            console.log('Failed to Login - User does not exist');
            localStorage.clear();

            this.globalService.alert(
                'User does not exist',
                'Dear user, your account does not exist. Please contact the administrator or click the support button on the right side of the screen to create a ticket.'
            );
            return;
        } else if (errorCallBack) {
            errorCallBack(data);
        }
        const _msg = data.error.msg ? data.error.msg : 'Unable to connect to the Server';
        if (environment.production) {
            console.log('Server Error ', data.status, ' - msg : ', _msg);
            this.globalService.alert(
                'Something went wrong',
                'Dear user, something went wrong with your last request. The Pulse Team has been notified and will work on it.'
            );
        } else {
            this.globalService.alert('Server Error', _msg);
        }
    }
    getJson(url, callback, unsubscribe: Subject<void>): any {
        this.globalService.loading(true);
        this.http
            .get(url)
            .pipe(takeUntil(unsubscribe), map(this.extractData))
            .subscribe((data) => {
                callback(data);
                this.globalService.loading(false);
            });
    }
    private extractData(res: Response): any {
        const body = res;
        return body['data'] || {};
    }
    public wkhtmltopdf(
        html: string,
        propid: string,
        user_id: number,
        report: string
    ): Observable<any> {
        return this.http.post(
            this.server + 'services/pdf/createPdf',
            { htmlString: html, propid: propid, user_id: user_id, report: report },
            {
                responseType: 'blob',
                headers: this.headers
            }
        );
    }
}
