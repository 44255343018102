import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import {
    HttpClientModule,
    HttpClientXsrfModule,
    HTTP_INTERCEPTORS
} from '@angular/common/http';
import {
    CUSTOM_ELEMENTS_SCHEMA,
    ErrorHandler,
    Injectable,
    NgModule
} from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as Sentry from '@sentry/browser';
import { DevExtremeModule, DxAccordionModule } from 'devextreme-angular';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, CustomInterceptor } from './app.component';
import { ComponentModule } from './core/components/components.module';
import { AlertComponent } from './main/alert/alert.component';
import { DashboardHeaderNavComponent } from './main/dashboard-header-nav/dashboard-header-nav.component';
import { UnwrapTagDirective } from './main/directives/unwrap-tag.directive';
import { EntitySidebarComponent } from './main/entity-sidebar/entity-sidebar.component';
import { LoadingComponent } from './main/loading/loading.component';
import { ScrollTopComponent } from './main/scroll-top/scroll-top.component';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { LogoutPage } from './pages/logout/logout.page';
import { SectionsModule } from './shared/sections/sections.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'ngx-moment';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from './core/pipes/pipes.module';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AvatarComponent } from 'app/core/components/avatar/avatar.component';
Sentry.init({
    dsn: 'https://7e65d7ebbcfe4c4ab062453ba3d93617@sentry.io/1328451',
    environment: environment.sentry_env ? environment.sentry_env : 'unknow',
    release: 'pulse-v2@1.0.0'
});
@Injectable({ providedIn: 'root' })
export class SentryErrorHandler implements ErrorHandler {
    //constructor() {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError(error: any): any {
        Sentry.captureException(error.originalError || error);
        throw error;
        //un-comment to display error reporting dialog
        //const eventId = Sentry.captureException(error.originalError || error);
        //Sentry.showReportDialog({ eventId });
    }
}
export function provideErrorHandler(): SentryErrorHandler {
    if (environment.production) {
        return new SentryErrorHandler();
    } else {
        // don't catch dev errors in sentry
        return new ErrorHandler();
    }
}
@NgModule({
    imports: [
        ComponentModule.forRoot(),
        CommonModule,
        SectionsModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'pulse.x-csrf-token',
            headerName: 'x-csrf-token'
        }),
        DxAccordionModule,
        NgIdleKeepaliveModule.forRoot(),
        MomentModule,
        ModalModule.forRoot(),
        NgbModule,
        PipesModule,
        DevExtremeModule,
        ProgressbarModule.forRoot(),
        MatExpansionModule,
        MatSelectModule,
        MatFormFieldModule,
        MatGridListModule,
        MatToolbarModule
    ],
    declarations: [
        DashboardPage,
        AppComponent,
        LogoutPage,
        EntitySidebarComponent,
        ScrollTopComponent,
        AlertComponent,
        LoadingComponent,
        DashboardHeaderNavComponent,
        UnwrapTagDirective,
        AvatarComponent
    ],
    //entryComponents: [CommentsDialogComponent],
    providers: [
        Title,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule {}
