import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DxFormModule, DxTemplateModule } from 'devextreme-angular';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDropDownBoxModule } from 'devextreme-angular/ui/drop-down-box';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxSwitchModule } from 'devextreme-angular/ui/switch';
import { DxTreeListModule } from 'devextreme-angular/ui/tree-list';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxEchartsModule } from 'ngx-echarts';
import { ContainerViewDirective } from '../directives/container-view.directive';
import { NumbersOnlyDirective } from '../directives/numbers-only.directive';
import { ExcelService } from '../services/excel.export.service';
import { LazyLoadExcelService } from '../services/lazyload.export.excel';
import { OrmaService } from '../services/orma.service';
import { FinanceService } from '../services/finance.service';
import { DashboardOptionsComponent } from './dashboard-options/dashboard-options.component';
import { DashboardDatacontrolComponent } from './dashboard-datacontrol/dashboard-datacontrol.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CommentsDialogComponent } from './popup/comments-dialog/comments-dialog.component';
import { PopupService } from './popup/popup.service';
import { SectionCommentsDialogComponent } from './popup/section-comments-dialog/section-comments-dialog.component';
import { SharedConfirmDialogComponent } from './popup/shared-dialogs/shared-confirm-dialog.component';
import { SectionCommentsComponent } from './section-comments/section-comments.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { AngularMaterialModule } from '../../material.module';
import {
    DxiColumnModule,
    DxoEditingModule,
    DxoFilterRowModule,
    DxoFormItemModule,
    DxoPagerModule,
    DxoSelectionModule
} from 'devextreme-angular/ui/nested';
import { SwiperModule } from 'swiper/angular';
import { FormsModule } from '@angular/forms';
// TO DO : review the list of modules to import / export and remove the unused ones
@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        DxDropDownBoxModule,
        DxListModule,
        DxTreeListModule,
        DxSwitchModule,
        DxDataGridModule,
        DxFormModule,
        DxiColumnModule,
        DxoSelectionModule,
        DxoEditingModule,
        DxoPagerModule,
        DxoFormItemModule,
        DxoFilterRowModule,
        DxTemplateModule,
        BsDatepickerModule.forRoot(),
        ProgressbarModule.forRoot(),
        BsDatepickerModule.forRoot(),
        AngularMaterialModule,
        SwiperModule,
        FormsModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        })
    ],
    declarations: [
        ContainerViewDirective,
        NumbersOnlyDirective,
        DashboardOptionsComponent,
        DashboardDatacontrolComponent,
        DashboardComponent,
        SectionCommentsComponent,
        CommentsDialogComponent,
        SectionCommentsDialogComponent,
        SharedConfirmDialogComponent
    ],
    exports: [
        // Common exports
        CommonModule,
        SwiperModule,
        NgxEchartsModule,
        AngularMaterialModule,
        NgbModule,
        // Dx exports
        DxDropDownBoxModule,
        DxListModule,
        DxTreeListModule,
        DxSwitchModule,
        DxDataGridModule,
        DxFormModule,
        DxiColumnModule,
        DxoSelectionModule,
        DxoEditingModule,
        DxoPagerModule,
        DxoFormItemModule,
        DxoFilterRowModule,
        DxTemplateModule,
        // Custom exports
        ContainerViewDirective,
        NumbersOnlyDirective,
        DashboardOptionsComponent,
        DashboardDatacontrolComponent,
        DashboardComponent,
        SectionCommentsComponent,
        CommentsDialogComponent,
        SectionCommentsDialogComponent,
        SharedConfirmDialogComponent
    ],
    providers: [PopupService, ExcelService, OrmaService, FinanceService, LazyLoadExcelService]
})
export class ComponentModule {
    static forRoot(): ModuleWithProviders<ComponentModule> {
        return {
            ngModule: ComponentModule
        };
    }
}
