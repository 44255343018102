/* eslint-disable @typescript-eslint/ban-types */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class CsrfService {
    private headers: HttpHeaders = new HttpHeaders({
        'Access-Control-Allow-Origin': '*'
    });
    public on_ready: BehaviorSubject<boolean> = new BehaviorSubject(false);
    constructor(public http: HttpClient) {}
    public set(token: string): void {
        localStorage.setItem('uuid', token);
    }
    public getCsrfToken(): string {
        return localStorage.getItem('uuid') || '';
    }

    public refreshCsrfToken(): Observable<{ csrfToken: string }> {
        console.log('refreshCsrfToken() called');
        return this.http.get<{ csrfToken: string }>('api/access/getToken');
    }

    public refreshCsrfTokenPromise(): Promise<string> {
        console.log('refreshCsrfTokenPromise() called');
        const promise = new Promise<string>((resolve, _reject) => {
            let _data: string = '';
            this.http.get<{ csrfToken: string }>('api/access/getToken').subscribe({
                next: (res: { csrfToken: string }) => {
                    _data = res.csrfToken;
                    resolve(_data);
                },
                error: (_err: unknown) => {
                    resolve('');
                },
                complete: () => {
                    console.log('refreshCsrfTokenPromise complete');
                }
            });
        });

        return promise;
    }

    public validateCsrfToken(): Promise<boolean> {
        this.headers = this.headers.set('x-csrf-token', localStorage.getItem('uuid'));
        const options = {
            headers: this.headers
        };
        /* return this.http.post<{ valid: boolean }>(
            'api/access/validateToken',
            {},
            options
        ); */
        const promise: Promise<boolean> = new Promise<boolean>((resolve, _reject) => {
            this.http
                .post<{ valid: boolean }>('api/access/validateToken', {}, options)
                .subscribe({
                    next: (res: { valid: boolean }) => {
                        console.log('validateCsrfToken is valid');
                        resolve(res.valid);
                    },
                    error: (_err: unknown) => {
                        console.log('validateCsrfToken is NOT valid');
                        resolve(false);
                    },
                    complete: () => {
                        console.log('complete');
                    }
                });
        });
        return promise;
    }
}
